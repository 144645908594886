import { default as _91_46_46_46page_93BUiSDMc0FLMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/public-portal/pages/[...page].vue?macro=true";
import { default as bedankt4qsIljOwxtMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/public-portal/pages/bedankt.vue?macro=true";
import { default as index3kiTj1m7tJMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/public-portal/pages/index.vue?macro=true";
import { default as wifi_45connectzHzZopMBucMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/public-portal/pages/wifi-connect.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93BUiSDMc0FLMeta?.name ?? "page",
    path: _91_46_46_46page_93BUiSDMc0FLMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93BUiSDMc0FLMeta || {},
    alias: _91_46_46_46page_93BUiSDMc0FLMeta?.alias || [],
    redirect: _91_46_46_46page_93BUiSDMc0FLMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/public-portal/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: bedankt4qsIljOwxtMeta?.name ?? "bedankt",
    path: bedankt4qsIljOwxtMeta?.path ?? "/bedankt",
    meta: bedankt4qsIljOwxtMeta || {},
    alias: bedankt4qsIljOwxtMeta?.alias || [],
    redirect: bedankt4qsIljOwxtMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/public-portal/pages/bedankt.vue").then(m => m.default || m)
  },
  {
    name: index3kiTj1m7tJMeta?.name ?? "index",
    path: index3kiTj1m7tJMeta?.path ?? "/",
    meta: index3kiTj1m7tJMeta || {},
    alias: index3kiTj1m7tJMeta?.alias || [],
    redirect: index3kiTj1m7tJMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/public-portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: wifi_45connectzHzZopMBucMeta?.name ?? "wifi-connect",
    path: wifi_45connectzHzZopMBucMeta?.path ?? "/wifi-connect",
    meta: wifi_45connectzHzZopMBucMeta || {},
    alias: wifi_45connectzHzZopMBucMeta?.alias || [],
    redirect: wifi_45connectzHzZopMBucMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/public-portal/pages/wifi-connect.vue").then(m => m.default || m)
  }
]
import {nl, en} from '@formkit/i18n';
import theme from './formkit.theme';
import {createFloatingLabelsPlugin} from '@formkit/addons';
import '@formkit/addons/css/floatingLabels';
import {createConfig} from '@formkit/core';
import {addAsteriskPlugin} from '@utils/formkit/asterisk-plugin';

export default createConfig({
  locales: {nl, en},
  locale: 'nl',
  config: {
    classes: theme,
  },
  plugins: [
    addAsteriskPlugin,
    createFloatingLabelsPlugin({
      useAsDefault: true,
    }),
  ],
});

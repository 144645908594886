import {generateClasses} from '@formkit/themes';

export default generateClasses({
  global: {
    help: 'text-xs text-gray-500',
    input: 'bg-transparent border-0 focus:outline-none focus:ring-0 focus:shadow-none',
    label: 'block !text-black !left-0 cursor-text',
    legend: 'font-bold text-sm',
    loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
    message: 'text-primary mb-1 text-sm',
    messages: 'list-none p-0 mt-1 mb-0',
  },

  // Family styles apply to all inputs that share a common family
  'family:box': {
    wrapper: 'flex items-center mb-1 gap-1.5',
    legend: 'font-medium text-sm mb-3',
    options: 'flex flex-wrap gap-x-10',
    label: 'text-sm',
    input: 'accent-primary',
  },
  'family:button': {
    inner: '$reset bg-primary',
  },
  'family:text': {
    inner: 'transition-all duration-300 flex items-center border-gray-200 border-b hover:border-gray-300 focus-within:!border-primary mb-1',
    input: 'w-full py-3 border-none text-base placeholder-gray-500',
  },
  radio: {
    decorator: 'rounded-full',
    decoratorIcon: 'w-5 p-[5px]',
  },
  select: {
    inner: 'transition-all duration-300 flex items-center ring-1 ring-gray-200 hover:ring-gray-300 focus-within:!ring-primary rounded-md mb-1 bg-white',
    input: 'w-full px-3 py-3.5 text-base placeholder-gray-500 border-r-8 border-solid border-white rounded-r-md',
    selectIcon: 'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none',
    option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-800',
    label: 'translate-y-1/2 bg-white px-2 inline-block text-sm ml-1',
  },
});
